import "bootstrap";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import BackToTop from "./components/BackToTop";
import Services from "./components/Services";
import Statistics from "./components/Statistics";
import initTestimonialCarousel from "./components/TestimonialCarousel";

// document.addEventListener("DOMContentLoaded", function () {
//   var lightboxTriggerElements = document.querySelectorAll(
//     '[data-bs-toggle="lightbox"]'
//   );
//   lightboxTriggerElements.forEach(function (element) {
//     element.addEventListener("click", function (event) {
//       event.preventDefault();
//       new Bs5Lightbox(event.target);
//     });
//   });
//
//   // Initialize Isotope
//   var grid = document.querySelector(".gallery");
//   var iso = new Isotope(grid, {
//     itemSelector: ".gallery__content-item",
//     percentPosition: true,
//     masonry: {
//       columnWidth: ".gallery__content-item",
//       gutter: ".gallery__content-gutter",
//     },
//   });
//
//   imagesLoaded(grid).on("progress", function () {
//     // layout Isotope after each image loads
//     iso.layout();
//   });
// });

document.querySelectorAll("header.site-header").forEach((header) => {
  new Header(header);
});

document.querySelectorAll(".mobile-menu").forEach((mobMen) => {
  new MobileMenu(mobMen);
});

document.querySelectorAll("#backtotop").forEach((block) => {
  new BackToTop(block);
});

document.querySelectorAll(".services").forEach((block) => {
  new Services(block);
});

document.querySelectorAll(".statistics").forEach((block) => {
  new Statistics(block);
});

initTestimonialCarousel();
