import _ from "lodash";

class Header {
  constructor(header) {
    this.header = header;
    this.body = document.querySelector("body");
    this.logo = header.querySelector(".site-header__logo-image");
    this.navItems = header.querySelectorAll(".site-header__nav-list-item");
    this.mobileMenuButton = header.querySelector(".site-header__button");
    this.mobileMenu = document.querySelector(".mobile-menu");
    this.isScrolled = false;

    this.setScrolled();
    this.setHeaderHeight();
    this.events();
  }

  events() {
    this.navItems.forEach((item) => {
      item.addEventListener("mouseenter", () => this.navOpen(item));
      item.addEventListener("mouseleave", () => this.navClose(item));
    });

    this.header.addEventListener("mouseleave", () => {
      this.navCloseAll();
    });

    this.mobileMenuButton.addEventListener("click", () =>
      this.toggleMobileMenu()
    );

    window.addEventListener(
      "scroll",
      _.throttle((e) => {
        this.setScrolled(e);
        this.setHeaderHeight();
      }, 250)
    );

    window.addEventListener(
      "resize",
      _.debounce(() => {
        this.setScrolled();
        this.setHeaderHeight();
      }, 250)
    );
  }

  navOpen(item) {
    this.navItems.forEach((el) => {
      el.classList.toggle("site-header__nav-list-item--active", el == item);
    });
  }

  navClose(item) {
    if (!item.querySelector(".site-header__nav-sublist")) {
      item.classList.remove("site-header__nav-list-item--active");
    }
  }

  navCloseAll() {
    this.navItems.forEach((el) => {
      el.classList.remove("site-header__nav-list-item--active");
    });
  }

  toggleMobileMenu() {
    this.mobileMenu.classList.toggle("mobile-menu--active");
  }

  setHeaderHeight() {
    this.body.style.setProperty(
      "--header-height",
      `${this.header.offsetHeight}px`
    );
  }

  setScrolled() {
    //if (window.scrollY > window.innerHeight - this.header.offsetHeight) {
    if (window.scrollY > 0) {
      this.header.classList.add("site-header--scrolled");
      this.isScrolled = true;
    } else {
      this.header.classList.remove("site-header--scrolled");
      this.isScrolled = false;
    }
  }
}

export default Header;
