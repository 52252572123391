import _ from "lodash"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class Services {
  constructor(block) {
    this.block = block
    this.servicesCount = this.block.querySelectorAll(".services__timeline-list-item").length
    this.timelineLinks = this.block.querySelectorAll(".services__timeline-list-item-link")
    this.topLeftContents = this.block.querySelectorAll(".services__top-left-content")
    this.bottomRightContents = this.block.querySelectorAll(".services__bottom-right-content")
    this.topRightImages = this.block.querySelectorAll(".services__top-right-image")
    this.bottomLeftImages = this.block.querySelectorAll(".services__bottom-left-image")
    this.mobileSections = this.block.querySelectorAll(".services__mobile-service")
    this.mobileTimeline = this.block.querySelector(".services__mobile-timeline")
    this.mobileTimelineList = this.block.querySelector(".services__mobile-timeline-list")
    this.mobileTimelineItems = this.block.querySelectorAll(".services__mobile-timeline-list-item")

    this.previousIndex = 0
    this.currentIndex = 0

    this.setContainerWidth()
    this.setInitialIndex()
    this.gsapInit()
    this.events()
  }

  events() {
    window.addEventListener(
      "resize",
      _.debounce(() => {
        this.setContainerWidth()
      }, 250)
    )

    this.mobileTimelineItems.forEach((item) => {
      item.querySelector(".services__mobile-timeline-list-item-link")?.addEventListener("click", (e) => this.scrollToMobileSection(e, item.dataset.id))
    })

    this.timelineLinks.forEach((link, index) => {
      link.addEventListener("click", (e) => this.scrollToSection(e, index))
    })
  }

  setContainerWidth() {
    this.block.style.setProperty("--container-width", `${document.querySelector(".container")?.offsetWidth}px`)
  }

  setInitialIndex() {
    this.block.style.setProperty("--service-index", this.currentIndex)
    this.topLeftContents[this.currentIndex].classList.add("services__top-left-content--active")
    this.bottomRightContents[this.currentIndex].classList.add("services__bottom-right-content--active")
    this.topRightImages[this.currentIndex].classList.add("services__top-right-image--active")
    this.bottomLeftImages[this.currentIndex].classList.add("services__bottom-left-image--active")
  }

  gsapInit() {
    this.mm = gsap.matchMedia()
    const headerHeight = document.querySelector(".site-header")?.offsetHeight ?? 0
    const adminBarHeight = document.querySelector("#wpadminbar")?.offsetHeight ?? 0

    this.mm.add("(min-width: 1240px)", () => {
      this.timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.block,
          start: () => `top ${headerHeight + adminBarHeight}px`,
          end: () => `+=${this.block.offsetHeight * (this.servicesCount - 1)}px`,
          pin: true,
          // snap: 1 / (this.servicesCount - 1),
          onUpdate: (self) => {
            this.currentIndex = Math.min(Math.max(Math.ceil(self.progress * this.servicesCount - 1), 0), this.servicesCount)

            if (this.currentIndex != this.previousIndex) {
              this.previousIndex = this.currentIndex
              this.block.style.setProperty("--service-index", this.currentIndex)

              this.topLeftContents.forEach((el) => {
                el.classList.toggle("services__top-left-content--active", el == this.topLeftContents[this.currentIndex])
              })

              this.bottomRightContents.forEach((el) => {
                el.classList.toggle("services__bottom-right-content--active", el == this.bottomRightContents[this.currentIndex])
              })

              this.topRightImages.forEach((el) => {
                el.classList.toggle("services__top-right-image--active", el == this.topRightImages[this.currentIndex])
              })

              this.bottomLeftImages.forEach((el) => {
                el.classList.toggle("services__bottom-left-image--active", el == this.bottomLeftImages[this.currentIndex])
              })
            }
          },
        },
      })
    })

    this.mm.add("(max-width: 1239px)", () => {
      this.mobileSections.forEach((section) => {
        ScrollTrigger.create({
          trigger: section,
          start: "top center",
          end: "bottom center",
          onToggle: (self) => {
            if (self.isActive) {
              this.setMobileTimeline(section.dataset.id)
            }
          },
        })
      })
    })
  }

  setMobileTimeline(id) {
    this.mobileTimelineItems.forEach((item) => {
      if (item.dataset.id == id) {
        item.classList.add("services__mobile-timeline-list-item--active")
        this.mobileTimelineList.style.setProperty("--position", `${item.offsetLeft}px`)
        this.mobileTimelineList.style.setProperty("--width", `${item.offsetWidth}px`)
      } else {
        item.classList.remove("services__mobile-timeline-list-item--active")
      }
    })
  }

  scrollToMobileSection(e, id) {
    e.preventDefault()

    const headerHeight = document.querySelector(".site-header")?.offsetHeight ?? 0
    const adminBarHeight = document.querySelector("#wpadminbar")?.offsetHeight ?? 0

    this.mobileSections.forEach((section) => {
      if (section.dataset.id == id) {
        window.scroll(0, section.offsetTop - headerHeight - adminBarHeight - this.mobileTimeline.offsetHeight)
      }
    })
  }

  scrollToSection(e, index) {
    e.preventDefault()

    const headerHeight = document.querySelector(".site-header")?.offsetHeight ?? 0

    window.scroll({
      left: 0,
      top: this.block.closest(".pin-spacer")?.offsetTop + this.block.offsetHeight * index - headerHeight,
      behavior: "instant",
    })
  }
}

export default Services
