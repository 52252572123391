import Flickity from "flickity";
export default function initTestimonialCarousel() {
  document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector(".testimonials-carousel__wrapper")) {
      new Flickity(".testimonials-carousel__wrapper", {
        cellAlign: "left",
        contain: true,
        adaptiveHeight: true,
        wrapAround: true,
        draggable: true,
        pageDots: false,
        groupCells: true,
      });
    }
  });
}
