class Statistics {
	constructor(block) {
		this.block = block;
		this.statsItems = block.querySelectorAll('.stats-item__value');
		this.observer = null;

		this.initObserver();
		this.events();
	}

	// Initialize the IntersectionObserver
	initObserver() {
		this.observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.animateStat(entry.target);
						this.observer.unobserve(entry.target);
					}
				});
			},
			{ threshold: 0.5 }
		);
	}

	// Set up the event listeners
	events() {
		this.statsItems.forEach((item) => {
			this.observer.observe(item);
		});
	}

	// Animate the statistic value
	animateStat(statElement) {
		const endValue = parseInt(statElement.getAttribute('data-final-value'), 10);
		const duration = Math.max(2000 * (endValue / 100), 1000);

		new CountUp(statElement, endValue, { duration }).start();
	}
}

class CountUp {
	constructor(element, endValue, options = {}) {
		this.element = element;
		this.endValue = endValue;
		this.startValue = 0;
		this.duration = options.duration || 2000;
		this.easing = options.easing || 'easeOutQuad';
		this.startTimestamp = null;

		this.easingFunctions = {
			easeOutQuad: (t) => t * (2 - t),
			easeOutCubic: (t) => --t * t * t + 1,
			easeOutQuart: (t) => 1 - --t * t * t * t,
		};

		this.easingFunction = this.easingFunctions[this.easing] || this.easingFunctions.easeOutQuad;
	}

	animate(timestamp) {
		if (!this.startTimestamp) this.startTimestamp = timestamp;
		const progress = Math.min((timestamp - this.startTimestamp) / this.duration, 1);
		const easedProgress = this.easingFunction(progress);
		const currentValue = Math.floor(easedProgress * (this.endValue - this.startValue) + this.startValue);
		this.element.innerText = currentValue;

		if (progress < 1) {
			requestAnimationFrame(this.animate.bind(this));
		}
	}

	start() {
		requestAnimationFrame(this.animate.bind(this));
	}
}

export default Statistics;
